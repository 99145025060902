import React, { useEffect, useState } from "react"
import Layout from "../components/layout";
import WishlistProducts from "../components/wishlist/wishlist-products";
import SEO from "../components/seo";
import { getWishListProducts } from "../utils/functions";
import { isEmpty } from "lodash";
import Link from 'gatsby-link';

const WishList = () => {

	useEffect(() => {
	document.getElementById('lang-switch').href="/pl/lista-zyczen/"
	}, []);

	const [ wishList, setWishList ] = useState( getWishListProducts() );

	return (
		<>
		<SEO
	    title={ 'Wishlist' }
	    header={ { siteTitle: ' Shroom | Shop' } }
	  />
		<Layout>
			<div className="container my-5">
				<div style={{textAlign:'center',padding:'0 10%'}}>
				<h1 className="mt-5 woo-next-cart-heading spec-h">Wishlist</h1>
				<p>This is the wishlist's page, you can add items for later here and they will be magically remembered in your future sessions.
				<br /><br />

				</p>
				</div>
				{
					!isEmpty( wishList ) && wishList.productIds.length ?
						(
						<>
						<p style={{textAlign:'center'}}>Do you want to buy those items? <Link className="text-primary" to='/cart/'>Add them to cart</Link>, then proceed to checkout.</p>
						<WishlistProducts setWishList={ setWishList }/>
						</>
						):
						 (<>
							<p style={{textAlign:'center'}}>There are no items in your wishlist <Link className="text-primary" to='/'>return to shop </Link>, then add some stuff.</p>
			        <div className="container mt-5" style={{height: '72vh',textAlign:'center'}}>
			          <h2 className="spec-h">No items on your wishlist</h2>
			          <p>Please, add new products to your wishlist.</p>
			          <Link to="/">
			            <button className="btn btn-primary woo-next-large-black-btn">
			              <span className="woo-next-cart-checkout-txt">
			                Return to shop
			              </span>
			              <i className="fas fa-long-arrow-alt-right" />
			            </button>
			          </Link>
			        </div>
							</>
			      )
				}
			</div>
		</Layout>
		</>
	)
}

export default WishList;
